import React, { useState, useEffect } from "react";
import DashboardLineChart from "./charts/DashboardLineChart";
import "../styling/Dashboard.css";
import RevenueInfoTile from "./tiles/RevenueInfoTile";
import AnalyticsPanel from "./panels/AnalyticsPanel";
import axios from 'axios';
import AnalyticsDonut from "./charts/AnalyticsDonut";
import AnalyticsSuccessBarGraph from "./charts/AnalyticsSuccessBarGraph";
import AnalyticsLineChart from "./charts/AnalyticsLineChart";
import AnalyticsCustomerBar from "./charts/AnalyticsCustomerBar";
import AnalyticsLeadDonut from "./charts/AnalyticsLeadDonut";


const Analytics = () => {
  //state to store the events 
  const [ event, setEvent  ] = useState([]); 

  const [tile, setTile] = useState(null);
  
  
  // useEffect hook to fetch event details when component mounts
  useEffect(() => {
    fetchDashboardData();
    fetchDashboardTileData();
  }, []);

  // Function to fetch events from API
  const fetchDashboardData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard-data`, { withCredentials: true });
      setEvent(response.data.data);
    } catch (error){
      console.log('Error fetching dashboard chart data... ', error);
    }
  };

  const fetchDashboardTileData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard-tile`, { withCredentials: true });
      setTile(response.data.data);
      console.log(response.data.data);
    } catch (error){
      console.log('Error fetching dashboard tile data... ', error);
    }
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const generateMonthYears = () => {
    

    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
  
    // Calculate the start month index for the past 12 months
    let startMonthIndex = currentMonthIndex - 11; // Subtract 11 to go back 12 months
    let startYear = currentYear;
    if (startMonthIndex < 0) {
        startMonthIndex += 12; // Wrap around to the previous year
        startYear -= 1; // Adjust start year accordingly
    }
  
    // Create an array of month-year strings for the last 12 months
    const monthYears = [];
    for (let i = 0; i < 12; i++) {
        const monthIndex = (startMonthIndex + i) % 12; // Handle wrap around
        const year = startYear + Math.floor((startMonthIndex + i) / 12); // Adjust year if needed
        monthYears.push(`${monthIndex} ${year}`);
    }
  
    return monthYears;
};
const monthYears = generateMonthYears(); 

const labels = monthYears.map((monthYear, index) => {
    const [month] = monthYear.split(' ');
    return   monthNames[month];
  }
 );
const data = monthYears.map(monthYear => {
  const [month, year] = monthYear.split(' ');
  const matchingEntry = event.find(entry => entry._id.month - 1 === parseInt(month) && entry._id.year === parseInt(year));
  return  matchingEntry ? matchingEntry.totalExpPaymentAmount : 0;
});

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: data,
        fill: false,
        borderColor: "#21A62C", // Line color
        tension: 0.2, // Line tension (0 to 1)
      },
    ],
  };

  const entries = [
    {},
    "Entry 2",
    "Entry 3",
    "Entry 4",
    // Add more entries as needed
  ];

  const chartOptions = {
    scales: {
      x: {
        type: "category",
        position: "bottom",
      },
      y: {
        type: "linear",
        min: 0,
        max: 100,
        grid: {
          display: false,
        },
      },
    },
    // maintainAspectRatio: false, // Set to false to allow changing dimensions
    // responsive: true,
    // width: 400, // Set the width
    // height: 200, // Set the height
    plugins: {
      title: {
        display: true,
        align: "start",
        text: "Revenue",

        color: "#1C1154",
        font: {
          size: 24,
          family: "Poppins",
        },
      },
      legend: {
        display: true,
        labels: {
          boxHeight: 0,
          boxWidth: 0,
        },
      },
    },
  };
  // TODO
 // Create a div for right panel, under that div keep the analytics panel, along with donuts. Keep the BG color this whole div as brown and content of it will be white BG. 
 // Lead source will be at book a booth below what is event.  
 return (
    <div>
        <h1 style={{ color: '#1C1154', marginRight:'30px', marginTop: '30px' }}>Analytics</h1>
        
      <RevenueInfoTile tile={tile || [{}, {}, {}, {}]} />
            <AnalyticsPanel style={{ marginBottom: '30px'}}/>
            <AnalyticsDonut/>
            <AnalyticsLeadDonut/>
      
      <div style={{ marginBottom: '20px' }}>
        <DashboardLineChart  data={chartData} options={chartOptions} />
        
      </div>
      <div style={{ marginBottom: '20px', marginTop: '30px' }}>
        <AnalyticsSuccessBarGraph style={{ marginTop: '30px' }}/>
        
      </div>
      <div style={{ marginBottom: '20px', marginTop: '30px' }}>
        <AnalyticsLineChart style={{ marginTop: '30px' }}/>
        
      </div>
      <div style={{ marginBottom: '20px', marginTop: '30px' }}>
        <AnalyticsCustomerBar style={{ marginTop: '30px' }}/>
        
      </div>
    </div>
  );
};

export default Analytics;