// Content.js
import React, { useState } from "react";
import "../styling/Content.css";
import AccountInfo from "./AccountInfo";
import Analytics from "./Analytics";
import Bookings from "./Bookings";
import BoothInfo from "./BoothInfo";
import BusinessClientEvent from "./clientPortal/BusinessClientEvent";
import Calendar from "./Calender";
import Clients from "./Clients";
import CompanyInfo from "./CompanyInfo";
import Dashboard from "./Dashboard";

const Content = ({
  selectedMenuItem,
  handleClientClick,
  onEventClick,
  rightPaneEventClick,
  onPanelEventClick,
  handleViewAllClick,
  setSelectedMenuItem, // Add setSelectedMenuItem prop
}) => {
  let contentComponent;

  const handleAccountInfoChange = (infoType) => {
    console.log("handleAccountInfoChange called with:", infoType); // Check if function is called
    if (
      infoType === "account" ||
      infoType === "company" ||
      infoType === "booth"
    ) {
      console.log("Setting selectedMenuItem to:", infoType); // Check if setting is happening
      setSelectedMenuItem(infoType);
    }
  };
  switch (selectedMenuItem) {
    case "Dashboard":
      contentComponent = (
        <Dashboard
          rightPaneEventClick={rightPaneEventClick}
          onPanelEventClick={onPanelEventClick}
          setSelectedMenuItem={setSelectedMenuItem}
          handleViewAllClick={handleViewAllClick}
        />
      );
      break;
    case "Bookings":
      contentComponent = <Bookings onEventClick={onEventClick} />;
      break;
    case "Clients":
      contentComponent = <Clients handleClientClick={handleClientClick} />;
      break;
    case "Analytics":
      contentComponent = <Analytics />;
      break;
    case "Calendar":
      contentComponent = <Calendar />;
      break;
    case "account":
      contentComponent = (
        <AccountInfo
          onClickAccountInfoButton={handleAccountInfoChange}
          initialSelected={selectedMenuItem}
        />
      );
      break;
    case "company":
      contentComponent = (
        <CompanyInfo onClickAccountInfoButton={handleAccountInfoChange} />
      );
      break;
    case "booth":
      contentComponent = (
        <BoothInfo onClickAccountInfoButton={handleAccountInfoChange} />
      );
      break;
    case "businessEvent":
      contentComponent = <BusinessClientEvent />;
      break;
    default:
      contentComponent = (
        <Dashboard
          rightPaneEventClick={rightPaneEventClick}
          onPanelEventClick={onPanelEventClick}
          handleViewAllClick={handleViewAllClick}
          setSelectedMenuItem={setSelectedMenuItem} // Pass setSelectedMenuItem to Dashboard
        />
      );
  }
  return <div className="content">{contentComponent}</div>;
};

export default Content;
