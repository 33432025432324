import React, { useState, useEffect } from "react";
import "../styling/Account.css";
import profilePic from "../images/John-Wick.jpg.avif";
import { FaUpload } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";

const BoothInfo = ({onClickAccountInfoButton, initialSelected}) => {
  const [selectedButton, setSelectedButton] = useState(initialSelected); 
  useEffect(() => {
    setSelectedButton(initialSelected);
  }, [initialSelected]);

  const handleButtonClick = (buttonType) => {
    console.log(buttonType, initialSelected );
    setSelectedButton(buttonType);
    onClickAccountInfoButton(buttonType); // Send data to parent
  };

    const packages = (<div className="invoiceContent" style={{ width: '92%', background: '#FFFFFF', padding: '8%' }}>
         <h2 style={{ color: "#1C1154", marginTop: "0px", marginBottom: '30px' }}>Packages</h2>
    <div
      className="right-panel-content"
      style={{ marginTop: "0px", padding: "20px" }}
    >
       
      <div className="right-panel-arrow-container">
        <span>
          <h2
            style={{
              color: "#1C1154",
              marginTop: "0px",
              marginBottom: "5px",
              fontWeight: "425",
            }}
          >
            Bronze Package
          </h2>
        </span>{" "}
        <h4
          style={{
            alignSelf: "flex-end",
            marginRight: "10px",
            marginBottom: "5px",
            color: "#1C1154",
            fontWeight: "425",
            marginTop: "auto",
          }}
        >
          {" "}
          $250.00{" "} <input style={{ paddingBottom: '3px'}} type="checkbox" />
        </h4>
      </div>

      <div style={{ color: "#4D4D4D" }}>
        <p style={{ marginBottom: "3px" }}>3 Hours On-Site Attendant</p>
        <p style={{ marginBottom: "3px", marginTop: "3px" }}>
          Unlimited Prints
        </p>
        <p style={{ marginBottom: "3px", marginTop: "3px" }}>
          Online Downloads
        </p>
        <p style={{ marginBottom: "3px", marginTop: "3px" }}>
          Party Props Custom
        </p>
        <p style={{ marginBottom: "3px", marginTop: "3px" }}>
          Photostrips Design
        </p>
      </div>
    </div>
    <div
      className="right-panel-content"
      style={{ marginTop: "20px", padding: "20px" }}
    >
       
      <div className="right-panel-arrow-container">
        <span>
          <h2
            style={{
              color: "#1C1154",
              marginTop: "0px",
              marginBottom: "5px",
              fontWeight: "425",
            }}
          >
           Silver Package
          </h2>
        </span>{" "}
        <h4
          style={{
            alignSelf: "flex-end",
            marginRight: "10px",
            marginBottom: "5px",
            color: "#1C1154",
            fontWeight: "425",
            marginTop: "auto",
          }}
        >
          {" "}
          $250.00{" "} <input style={{ paddingBottom: '3px'}} type="checkbox" />
        </h4>
      </div>

      <div style={{ color: "#4D4D4D" }}>
        <p style={{ marginBottom: "3px" }}>3 Hours On-Site Attendant</p>
        <p style={{ marginBottom: "3px", marginTop: "3px" }}>
          Unlimited Prints
        </p>
        <p style={{ marginBottom: "3px", marginTop: "3px" }}>
          Online Downloads
        </p>
        <p style={{ marginBottom: "3px", marginTop: "3px" }}>
          Party Props Custom
        </p>
        <p style={{ marginBottom: "3px", marginTop: "3px" }}>
          Photostrips Design
        </p>
      </div>
    </div>
    <div
      className="right-panel-content"
      style={{ marginTop: "20px", padding: "20px" }}
    >
       
      <div className="right-panel-arrow-container">
        <span>
          <h2
            style={{
              color: "#1C1154",
              marginTop: "0px",
              marginBottom: "5px",
              fontWeight: "425",
            }}
          >
            Gold Package
          </h2>
        </span>{" "}
        <h4
          style={{
            alignSelf: "flex-end",
            marginRight: "10px",
            marginBottom: "5px",
            color: "#1C1154",
            fontWeight: "425",
            marginTop: "auto",
          }}
        >
          {" "}
          $250.00{" "} <input style={{ paddingBottom: '3px'}} type="checkbox" />
        </h4>
      </div>

      <div style={{ color: "#4D4D4D" }}>
        <p style={{ marginBottom: "3px" }}>3 Hours On-Site Attendant</p>
        <p style={{ marginBottom: "3px", marginTop: "3px" }}>
          Unlimited Prints
        </p>
        <p style={{ marginBottom: "3px", marginTop: "3px" }}>
          Online Downloads
        </p>
        <p style={{ marginBottom: "3px", marginTop: "3px" }}>
          Party Props Custom
        </p>
        <p style={{ marginBottom: "3px", marginTop: "3px" }}>
          Photostrips Design
        </p>
      </div>
    </div>
    <h2 style={{ marginTop: "30px", color: "#1C1154" }}>Add Ons</h2>
    <div className="bordered-box">
  <img src={profilePic} alt="Your Image" className="image" />
  <div className="content">
    <div style={{ color: "#1C1154" }}>
      {" "}
      Custom Party Props{" "}
    </div>
    <p
      style={{ color: "#4D4D4D", marginTop: "7px", marginBottom: "5px" }}
    >
      Let us know what theme and colors {" "}
    </p>
    <p style={{ color: "#4D4D4D", marginTop: "0px" }}>
      {" "}
      you want and we’ll make it happen!
    </p>
  </div>
  <div>
    <p className="rightmost-content" style={{ marginTop: '-20px', color: '#1C1154' }}>
      $150.00 <input style={{ paddingBottom: '3px'}} type="checkbox" />
    </p>
  </div>
</div>
<div
        style={{
          height: "300px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          paddingTop: "5%",
          width: "100%"
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "25px" }}
        >
          Payment Schedule
        </h2>
        <div style={{ width: "100%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Initial Deposit
            </div>
            <input
              placeholder="*************"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <div style={{ width: "100%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Final Payment
            </div>
            <input
              placeholder="*************"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <div style={{ width: "100%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Final Payment Due Date
            </div>
            <input
              placeholder="John Doe"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <button style={{ marginTop: '35px', height: '40px', width: '100%', background: '#260F99', border: '1px solid #43425D ', color: '#FFFFFF', fontSize: '17px', fontWeight: '450' }}>
          Update Book-A-Booth Link
          </button>
      </div>
</div>);



  return (
    <div style={{ width: '80%'}}>
        <div className="accountInfo">
        <div>
          {" "}
          <h2>Book-A-Booth</h2>{" "}
        </div>
        <div
          className="accountButtons"
          style={{ width: "35%", marginRight: "0w.5%" }}
        >
          <button style={{ height: "40px", fontSize: "14px", background: '#FFFFFF', color: '#1C1154'  }} onClick= {() => handleButtonClick("account")}> Account</button>
          <button style={{ height: "40px", fontSize: "14px", background: '#FFFFFF', color: '#1C1154' }} onClick= {() => handleButtonClick("company")}> Company</button>
          <button style={{ height: "40px", fontSize: "14px" }} onClick= {() => handleButtonClick("booth")}>
            {" "}
            Book-A-Booth
          </button>
        </div>
      </div>
       {packages} 
    </div>
  );
};

export default BoothInfo;
