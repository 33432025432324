import "../styling/App.css";
import React, { useState, useEffect } from "react";
import LeftMenu from "./LeftMenu";
import Content from "./Content";
import Header from "./header";
import BusinessClientView from "./BusinessClientView";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import BookABooth from "./BookABooth";
import Views from "./Views";
import BookABooth2 from "./BookABooth2";
import Login from "./Login";
import Password from "./Password";
import SignUp from "./SignUp";
import Membership from "../components/payments/Membership";
import StripePayment from "../components/payments/StripePayment";
import PaymentSuccess from "./payments/ClientLogin";
import StripeOnboarding from "./StripeOnboarding";
import ClientPortal from "./clientPortal/ClientPortal";
import ClientLogin from "./payments/ClientLogin";
import ClientLandingPage from "./clientPortal/ClientLandingPage";
import ClientContainer from "./clientPortal/ClientContainer";
import BookABoothLink from "./BookABoothLink";
import ClientEmailVerify from "./clientPortal/ClientEmailVerify";
import { EventDataProvider } from "./EventDataContext";
import CheckVerification from "./CheckVerification";

function App() {
  const [selectedMenuItem, setSelectedMenuItem] = useState("");
  const [selectedClientView, setSelectedClientView] = useState(true);

  const [isClient, setIsClient] = useState(false); // Manage client state

  const location = useLocation();
  const navigate = useNavigate();
  const { businessId } = useParams();
  console.log(businessId);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const clientStatus = params.get("isClient");
    if (clientStatus) {
      setIsClient(clientStatus === "true"); // Convert string to boolean
    }
  }, [location]);

  const [clientRecord, setClientRecord] = useState(null);

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem.text);
  };

  const handleIsclient = (clientFlag) => {
    setIsClient(clientFlag); // Update the client flag
    navigate(`/?isClient=${clientFlag}`); // Navigate with flag
  };

  const onClientClick = (record) => {
    setClientRecord(record);
    setSelectedClientView(!selectedClientView);
  };

  const onClickBacktoAccount = () => {
    setSelectedClientView(!selectedClientView);
  };

  const onEventClick = (eventId) => {
    console.log(eventId);
    setSelectedMenuItem("businessEvent");
  };

  const rightPaneEventClick = () => {
    setSelectedMenuItem("Bookings");
  };

  const onPanelEventClick = () => {
    setSelectedMenuItem("businessEvent");
  };

  // const onClickAccountInfoButton = (account) => {
  //   setSelectedMenuItem(account);
  // }

  const handleDropdownClick = (account) => {
    console.log(account);
    setSelectedMenuItem(account);
    setSelectedClientView(true);
  };

  return (
    <div className="appContain">
      <EventDataProvider>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Views
                handleMenuItemClick={handleMenuItemClick}
                selectedMenuItem={selectedMenuItem}
                setSelectedMenuItem={setSelectedMenuItem}
                handleDropdownClick={handleDropdownClick}
                onEventClick={onEventClick}
                handleViewAllClick={rightPaneEventClick}
                rightPaneEventClick={rightPaneEventClick}
                onPanelEventClick={onPanelEventClick}
              />
            }
          />
          <Route exact path="/booth2" element={<BookABooth2 />} />
          <Route exact path="/booth" element={<BookABooth />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route
            exact
            path="/login"
            element={<Login handleIsclient={handleIsclient} />}
          />
          <Route exact path="/password" element={<Password />} />
          <Route exact path="/membership" element={<Membership />} />
          <Route exact path="/payment" element={<StripePayment />} />
          <Route
            exact
            path="/stripeOnboarding"
            element={<StripeOnboarding />}
          />
          <Route exact path="/client-portal" element={<ClientPortal />} />
          <Route exact path="/client-login" element={<ClientLogin />} />
          <Route exact path="/client-home" element={<ClientContainer />} />
          <Route exact path="/book-a-booth" element={<BookABoothLink />} />
          <Route exact path="/verify-email" element={<ClientEmailVerify />} />
          <Route
            exact
            path="/check-verification"
            element={<CheckVerification />}
          />
        </Routes>
      </EventDataProvider>
    </div>
  );
}

export default App;
