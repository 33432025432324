import React from "react";
import  Modal  from "react-modal";
import '../../styling/Modal.css'

const CreateBookingModal = ( {isModalOpen, closeModal } ) => {
    
    const modalContent = (
        <div>
          <button className="close-button" onClick={closeModal}>
            &#10005;
          </button>
          <form>
        <div className="form-fields">
          <div className="form-field">
            <label htmlFor="clientName">Client Name:</label>
            <input type="text" id="clientName" name="clientName" />
          </div>

          <div className="form-field">
            <label htmlFor="bookingDate">Date:</label>
            <input type="date" id="bookingDate" name="bookingDate" />
          </div>

          <div className="form-field">
            <label htmlFor="bookingTime">Time:</label>
            <input type="time" id="bookingTime" name="bookingTime" />
          </div>

          <div className="form-field">
            <label htmlFor="bookingLocation">Location:</label>
            <input type="text" id="bookingLocation" name="bookingLocation" />
          </div>
        </div>

        
      </form>
      <button type="button" onClick={closeModal} className="close-button-bottom-right">
               Add Event
            </button>
      
        </div>
      );

    return (
        <div>
            <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            ariaHideApp={false}
            className="custom-modal"
          >
             <div>
              <h2 style={{ color: '#1C1154', fontWeight: '400' }}>Create New Event</h2>
              {modalContent}
            <button type="button" onClick={closeModal} className="close-button-bottom-right">
               + Add Event
            </button>
        
            </div>
            
          </Modal>
        </div>
    );
}

export default CreateBookingModal;