import React, { useState, useEffect } from "react";
import "../styling/Bookings.css";
import EventsTable from "./tables/EventsTable";
import EventInfoTile from "./tiles/EventInfoTile";
import CreateBookingModal from "./modals/CreateBookingModal";
import axios from "axios";

const Bookings = ({ onEventClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [event, setEvent] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openNewBookingsModal = () => {
    setIsModalOpen(true);
  };

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/business/events`, {
        withCredentials: true,
      });
      console.log(response.data.data);
      setEvent(response.data.data);

      console.log(event);
    } catch (error) {
      console.log(
        "Something went wrong while fetching latest events....",
        error
      );
    }
  };

  return (
    <div>
      <button
        type="button"
        className="events-add-button"
        onClick={openNewBookingsModal}
        style={{ color: "#2A1980" }}
      >
        + Add New Event
      </button>
      <h1 style={{ color: "#1C1154", margin: "30px" }}>Events</h1>

      <EventInfoTile  />
      <EventsTable event={event} onEventClick={onEventClick} />
      <CreateBookingModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default Bookings;
