import { useState } from "react";
import axios from "axios";
import "../../styling/Header.css";
import profilePic from "../../images/profilePic.jpg";
import { IoNotifications } from "react-icons/io5";


const ClientHeader = ({ clientId, onAccountDropdownClick }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  // Fetch notifications from the API
  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/client-user`, { withCredentials: true}); // Replace with actual API endpoint

      console.log(response);
      const newNotifications = response.data.data.notification || [];

      // Calculate unread notifications
      const unreadNotifications = newNotifications.filter((n) => !n.isRead).length;

      setNotifications(newNotifications);
      setUnreadCount(unreadNotifications);
      console.log(newNotifications);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  // Toggle notifications panel and fetch notifications
  const toggleNotifications = () => {
    setShowNotifications((prev) => !prev);

    // Fetch notifications only when opening the panel
    if (!showNotifications) {
      fetchNotifications();
    }
  };

  // Mark notifications as read
  const markAsRead = async () => {
    try {
      await axios.post(`/api/client/${clientId}/mark-as-read`); // Replace with actual API endpoint
      setUnreadCount(0); // Reset unread count
      fetchNotifications(); // Refresh notifications
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  return (
    <div>
      <header className="header" style={{ height: "50px", background: "#ffffff" }}>
        <div style={{ display: "flex", width: "214%", marginTop: "-11px" }} >
          <img
            style={{ marginLeft: "1%", width: "15%" } }
            src="https://cdn.prod.website-files.com/65997e123627c8e86f726abe/65a5db5cbf3e4af51b463bd7_Boothbloom-logo-color.svg"
            alt=""
            className="clientBoothLogo"
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "17rem",
            alignItems: "center",
            position: "relative",
          }}
        >
          {/* Bell Icon with Unread Counter */}
          <div className="notification" onClick={toggleNotifications}>
            <IoNotifications style={{ fontSize: "24px", color: "rgb(29,16,83)" }} />
            {unreadCount > 0 && (
              <span className="notification-counter">{unreadCount}</span>
            )}
          </div>
          <div className="profile" onClick={onAccountDropdownClick}>
            <img src={profilePic} alt="" />
          </div>
        </div>
      </header>

      {/* Notification Layover */}
      {showNotifications && (
        <div className="notification-layover">
          <div className="notification-title">
            <h2>Notifications</h2>
            <hr />
          </div>
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <div
                key={index}
                className={`notification-item ${notification.isRead ? "" : "unread"}`}
                onClick={() => console.log("Notification clicked:", notification)}
              >
                <h4>{notification.title}</h4>
                <p>{notification.message}</p>
                <span className="timestamp">
                  {new Date(notification.timestamp).toLocaleString()}
                </span>
              </div>
            ))
          ) : (
            <p className="no-notifications">No new notifications</p>
          )}
          {/* <button onClick={markAsRead} className="mark-as-read-btn">
            Mark all as read
          </button> */}
        </div>
      )}
    </div>
  );
};

export default ClientHeader;