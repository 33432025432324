import React from "react";
import '../../styling/RevenueInfoTile.css';

const EventInfoTile = () => {
    const column = {
        flex: "1",
        border: "1px solid #D9D9D9",
        borderLeft: "hidden",
        borderTop: "hidden",
        marginLeft: "30px",
        textAlign: "left",
        height: "65.5%",
        color: "#4D4D4D",
        marginTop: "16px",
        borderBottom: "hidden"
    };

    const pStyle = { marginTop: '0px', marginBottom: '10px'};
    const lastColumn = {...column, borderRight: "hidden"};

  return (
    <div
      style={{
        width: "96.7%",
        height: "90px",
        background: "#FFFFFF",
        marginTop: "29px",
        display: "flex",
        marginLeft:"30px",
        border: "1px solid #D9D9D9",
        borderBottom: "hidden",
        borderRadius: '5px',
        boxShadow: '0px 2px 4px #15223214',
      }}
    >
      <div style={column}>
        <p style={pStyle}> Total Events</p>
        <h1 style={{ color: '#1C1154'}}>160</h1>
      </div>
      <div style={column}>
        <p style={pStyle}> Upcoming Events </p>
        <h1 style={{ color: '#1C1154'}}>10</h1>
      </div>
      <div style={column}>
        <p style={pStyle}> Completed Events</p>
        <h1 style={{ color: '#1C1154'}}>146</h1>
      </div>
      <div style={lastColumn}>
        <p style={pStyle}>Pending Payments</p>
        <h1 style={{ color: '#1C1154'}}>23</h1>
      </div>
    </div>
  );
};

export default EventInfoTile;
