import React from 'react';
import { Chart as ChartJS, LinearScale, Legend } from "chart.js/auto";
import { Line } from 'react-chartjs-2';

const DashboardLineChart = ({ data, options }) => {
  ChartJS.register(LinearScale, Legend);

  // Merge default font options with any provided options
  const mergedOptions = {
    ...options,
    plugins: {
      ...options?.plugins, // Preserve existing plugins
      legend: {
        ...options?.plugins?.legend, // Preserve existing legend options
        display: false, // Hide the legend
        labels: {
          ...options?.plugins?.legend?.labels, // Preserve existing labels options
          font: {
            family: 'Poppins', // Use Poppins as specified
            size: 12,
            weight: 'normal',
          },
        },
      },
    },
    scales: {
      ...options?.scales, // Preserve existing scales
      x: {
        ...options?.scales?.x, // Preserve existing x-axis options
        ticks: {
          ...options?.scales?.x?.ticks, // Preserve existing ticks options
          font: {
            family: 'Poppins',
            size: 15,
            weight: 'normal',
          },
          padding: 15,
        },
        title: {
          ...options?.scales?.x?.title,
          font: {
            family: 'Poppins',
            size: 15,
            weight: 'bold',
          },
        },
      },
      y: {
        ...options?.scales?.y,
        ticks: {
          ...options?.scales?.y?.ticks,
          font: {
            family: 'Poppins',
            size: 11,
            weight: 'normal',
          },
        },
        title: {
          ...options?.scales?.y?.title,
          font: {
            family: 'Poppins',
            size: 11,
            weight: 'bold',
          },
          margin: { // Add padding here
            bottom: 50, // Adjust the value as needed
          },

        },
      },
    }
  };

  return (
    <div style={{ width: '72%', height: '100%', backgroundColor: '#FFFFFF', paddingLeft: '1.35%', paddingTop: '1%', fontFamily: 'poppins', boxShadow: '0px 2px 4px #15223214' }}>
      <Line data={data} options={mergedOptions} />
    </div>
  );
};

export default DashboardLineChart;
