import React, { useState, useEffect } from "react";
import DashboardLineChart from "./charts/DashboardLineChart";
import "../styling/Dashboard.css";
import TableDashboard from "./tables/TableDashboard";
import RevenueInfoTile from "./tiles/RevenueInfoTile";
import RightPanel from "./panels/RightPanel";
import MembersPanel from "./panels/MembersPanel";
import axios from "axios";


const Dashboard = ({
  rightPaneEventClick,
  onPanelEventClick,
  setSelectedMenuItem,
  handleViewAllClick,
}) => {
  //state to store the events
  const [event, setEvent] = useState([]);

  const [latestEvents, setLatestEvents] = useState(null);

  const [tile, setTile] = useState(null);

  const [teamMembers, setTeamMembers] = useState([]);

  // useEffect hook to fetch event details when component mounts
  useEffect(() => {
    fetchDashboardData();
    fetchDashboardTileData();
    fetchLatestEvents();
    fetchTeamMembers();
  }, []);

  // Function to fetch events from API
  const fetchDashboardData = async () => {
    console.log("asdgsad");
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard-data`, {
        withCredentials: true,
      });
      setEvent(response.data.data);
    } catch (error) {
      console.log("Error fetching dashboard chart data... ", error);
    }
  };

  const fetchLatestEvents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/business/events`, {
        withCredentials: true,
      });
      console.log(response.data.data);
      setLatestEvents(response.data.data);

      console.log(latestEvents);
    } catch (error) {
      console.log(
        "Something went wrong while fetching latest events....",
        error
      );
    }
  };

  const fetchDashboardTileData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard-tile`, {
        withCredentials: true,
      });
      setTile(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.log("Error fetching dashboard tile data... ", error);
    }
  };

  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-members`, {
        withCredentials: true,
      });
      setTeamMembers(response.data.data);
    } catch (error) {
      console.log("Error fetching the team members list.. ", error);
    }
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const generateMonthYears = () => {
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Calculate the start month index for the past 12 months
    let startMonthIndex = currentMonthIndex - 11; // Subtract 11 to go back 12 months
    let startYear = currentYear;
    if (startMonthIndex < 0) {
      startMonthIndex += 12; // Wrap around to the previous year
      startYear -= 1; // Adjust start year accordingly
    }

    // Create an array of month-year strings for the last 12 months
    const monthYears = [];
    for (let i = 0; i < 12; i++) {
      const monthIndex = (startMonthIndex + i) % 12; // Handle wrap around
      const year = startYear + Math.floor((startMonthIndex + i) / 12); // Adjust year if needed
      monthYears.push(`${monthIndex} ${year}`);
    }

    return monthYears;
  };
  const monthYears = generateMonthYears();

  const labels = monthYears.map((monthYear, index) => {
    const [month] = monthYear.split(" ");
    return monthNames[month];
  });
  const data = monthYears.map((monthYear) => {
    const [month, year] = monthYear.split(" ");
    const matchingEntry = event.find(
      (entry) =>
        entry._id.month - 1 === parseInt(month) &&
        entry._id.year === parseInt(year)
    );
    return matchingEntry ? matchingEntry.totalExpPaymentAmount : 0;
  });

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: data,
        fill: false,
        borderColor: "#260F99", // Line color
        tension: 0.2, // Line tension (0 to 1)
        font: {
          family: "Poppins",
        },
      },
    ],
  };

  const entries = [
    {},
    "Entry 2",
    "Entry 3",
    "Entry 4",
    // Add more entries as needed
  ];

  const chartOptions = {
    scales: {
      x: {
        type: "category",
        position: "bottom",
      },
      y: {
        type: "linear",
        min: 0,
        max: 100,
        grid: {
          display: false,
        },
      },
    },
    // maintainAspectRatio: false, // Set to false to allow changing dimensions
    // responsive: true,
    // width: 400, // Set the width
    // height: 200, // Set the height
    plugins: {
      title: {
        display: true,
        align: "start",
        text: "Revenue",

        color: "#000000",
        font: {
          size: 24,
          family: "Poppins",
          weight: "500",
        },
        padding: 30,
      },
    },
  };

  // const handleViewAllClick = () => {
  //   setSelectedMenuItem("Bookings"); // Redirect to Bookings page
  // };
  return (
    <div>
      <div>
        <RevenueInfoTile tile={tile || [{}, {}, {}, {}]} />
        <RightPanel
          latestEvent={latestEvents || [{}, {}, {}]}
          teamMembers={teamMembers || []}
          rightPaneEventClick={rightPaneEventClick}
          onPanelEventClick={onPanelEventClick}
          setSelectedMenuItem={setSelectedMenuItem}
          handleViewAllClick={handleViewAllClick}
        />
        {/* <MembersPanel /> */}
      </div>

      <div style={{ borderRadius: "5px" }}>
        <DashboardLineChart data={chartData} options={chartOptions} />

        <TableDashboard />
      </div>
    </div>
  );
};

export default Dashboard;
