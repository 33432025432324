import React, { useEffect, useState } from "react";
import { PiCaretRightBold } from "react-icons/pi";
import MembersPanel from "./MembersPanel";
import "../../styling/Dashboard.css";

const RightPanel = ({
  latestEvent,
  teamMembers,
  onPanelEventClick,
  rightPaneEventClick,
  setSelectedMenuItem,
}) => {
  const createDates = (latestEvent) => {
    return latestEvent?.slice(0, 3).map((event) => {
      const date = new Date(event.eventDate);
      return date.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
    });
  };

  let dates = createDates(latestEvent);
  const handleViewAllClick = () => {
    setSelectedMenuItem("Bookings");
  };
  return (
    <div className="right-panel">
      <div>
        <h3
          style={{
            color: "#000",
            marginTop: "0px",
            fontWeight: "500",
            fontSize: "20px",
            paddingTop: "10px",
          }}
        >
          New Bookings
        </h3>

        {/* Use .map() to avoid manually repeating JSX */}
        {latestEvent?.slice(0, 3).map((event, index) => (
          <div
            className="right-panel-content"
            key={event.id || `event-${index}`}
            onClick={() => {
              if (onPanelEventClick) {
                onPanelEventClick(event);
                // rightPaneEventClick(event);
              } else if (rightPaneEventClick) {
                rightPaneEventClick(event);
                // onPanelEventClick(event);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <h4
              style={{
                color: "#1C1154",
                marginTop: "0px",
                marginBottom: "5px",
                fontWeight: "525",
              }}
            >
              {event.eventName}
            </h4>
            <div className="right-panel-arrow-container">
              <span>{dates[index]}</span>
              <PiCaretRightBold className="right-panel-content-icon" />
            </div>
            <div style={{ paddingTop: "3px", paddingBottom: "15px" }}>
              {event.clientName}
            </div>
          </div>
        ))}

        <div style={{ marginTop: "15px" }}>
          <button className="createButtonStyleRP" onClick={handleViewAllClick}>
            View All
          </button>
        </div>
      </div>
      <MembersPanel teamMembers={teamMembers} />
    </div>
  );
};

export default RightPanel;
