import React from "react";
import "../../styling/Dashboard.css";
import profilePic from "../../images/profilePic.jpg";
import { GoSmiley } from "react-icons/go";
import axios from "axios";

const MembersPanel = ({ teamMembers }) => {

 const memberInfo = (member) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: "4px",
        padding: "2px",
      }}
      key={member._id} // Use member._id as a unique key for each memberInfo
    >
      <div
        style={{ marginRight: "10px" }}
      >
        <img
          src={profilePic}
          alt="Profile"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      </div>
      <div>
        <h4 style={{ margin: "0", fontWeight: "500", color: "#1C1154" }}>
          {member.name}
        </h4>
        <p style={{ margin: "0", color: "#4D4D4D" }}>Software Engineer</p>
      </div>
    </div>
 );

const smiley = (
    <div style={{ marginRight: '8px', marginTop: '4px' }}>
      <GoSmiley />
    </div>
 );

 return (
    <div className="members-panel">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          width: "87%",
        }}
      >
        <h3
          style={{
            fontWeight: "500",
            color: "#000000",
            marginTop: "0px",
            fontSize: "20px",
          }}
        >
          {" "}
          Team Members
        </h3>
        <div style={{ color: "#4D4D4D", right: "0" }}>
          {"Total: "}
          {teamMembers.length}
        </div>
      </div>
      <div>
        {teamMembers.map(memberInfo)}
      </div>
      <div style={{ marginTop: "15px" }}>
        <button className="addTeamMemberButton"> {smiley} Add Team Member </button>
      </div>
    </div>
 );
};

export default MembersPanel;