import { useState, useEffect } from "react";
import React from "react";
import "../styling/Account.css";
import profilePicture from "../images/John-Wick.jpg.avif";
import { FaUpload } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";

const AccountInfo = ({onClickAccountInfoButton, initialSelected}) => {
  const [selectedButton, setSelectedButton] = useState(initialSelected); 
  
  useEffect(() => {
    setSelectedButton(initialSelected);
  }, [initialSelected]);

  const handleButtonClick = (buttonType) => {
    console.log(buttonType, initialSelected );
    setSelectedButton(buttonType);
    onClickAccountInfoButton(buttonType); // Send data to parent
  };
  return (
    <div style={{ width: "80%" }}>
      <div className="accountInfo">
        <div>
          {" "}
          <h2>Account</h2>{" "}
        </div>
        <div
          className="accountButtons"
          style={{ width: "35%", marginRight: "0w.5%" }}
        >
          <button style={{ height: "40px", fontSize: "14px" }} onClick= {() => handleButtonClick("account")}> Account</button>
          <button style={{ height: "40px", fontSize: "14px", background: '#FFFFFF', color: '#1C1154' }} onClick= {() => handleButtonClick("company")}> Company</button>
          <button style={{ height: "40px", fontSize: "14px", background: '#FFFFFF', color: '#1C1154'  }} onClick= {() => handleButtonClick("booth")}>
            {" "}
            Book-A-Booth
          </button>
        </div>
      </div>
      <div
        style={{
          height: "130px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "25px" }}
        >
          Profile Image
        </h2>
        <div style={{ display: "flex" }}>
          <img
            src={profilePicture}
            alt="Profile"
            style={{ width: "70px", height: "70px", borderRadius: "50%" }}
          />
          <div
            className="accountButtons"
            style={{
              gap: "17px",
              marginLeft: "4%",
              width: "100%",
              alignItems: "center",
            }}
          >
            <button
              style={{
                width: "13.5%",
                height: "40px",
                fontSize: "14px",
                background: "#FFFFFF",
                border: "1px solid #D9D9D9",
                color: "#1C1154",
              }}
            >
              {" "}
              <FaUpload /> Upload
            </button>
            <button
              style={{
                width: "13%",
                height: "40px",
                fontSize: "14px",
                background: "#F2F2F2",
                border: "1px solid #D9D9D9",
                color: "#1C1154",
              }}
            >
              {" "}
              <FaTrash /> Delete
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "300px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "25px" }}
        >
          Account Info
        </h2>
        <div style={{ display: 'flex' }}>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              First Name
            </div>
            <input
              placeholder="John"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Last Name
            </div>
            <input
              placeholder="Doe"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Email
            </div>
            <input
              placeholder="JohnDoe@gamail.com"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Phone Number
            </div>
            <input
              placeholder="(000) 000-0000"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#E5E1FA",
            height: "55px",
            marginTop: "35px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "1.5%", marginTop: '3px' }}>
            <button
              style={{
                height: "55px",
                fontSize: "14px",
                padding: "0px",
                background: "#260F99",
                width: "87%",
                color: "#FFFFFF",
                border: "hidden",
              }}
            ></button>
          </div>
          <div
            style={{ color: "#4D4D4D", fontSize: "20px", marginLeft: "1.5%" }}
          >
            Please confirm you BoothBloom account by confirming your email
            account.
          </div>
          <div style={{ width: "17%" }}>
            <button
              style={{
                height: "40px",
                fontSize: "14px",
                padding: "0px",
                background: "#260F99",
                width: "87%",
                color: "#FFFFFF",
                border: "hidden",
              }}
            >
              {" "}
              <FaRegCircleCheck
                style={{ height: "12px", marginTop: "2px" }}
              />{" "}
              Confirm Email
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "346px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "25px" }}
        >
          Password Security
        </h2>
        <div style={{ width: "100%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Current Password
            </div>
            <input
              placeholder="*************"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <div style={{ width: "100%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              New Password
            </div>
            <input
              placeholder="*************"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <div style={{ width: "100%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Confirm Password
            </div>
            <input
              placeholder="*************"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <button style={{ marginTop: '20px', height: '40px', width: '30%', background: '#FFFFFF', border: '1px solid #D9D9D9 ', color: '#1C1154', fontSize: '17px', fontWeight: '450' }}>
                Change Password
          </button>
      </div>
      <div
        style={{
          height: "260px",
          backgroundColor: "#FFFFFF",
          marginBottom: "20px",
          padding: "6%",
          paddingTop: "5%",
        }}
      >
        <h2
          style={{ color: "#1C1154", fontWeight: "450", marginBottom: "25px" }}
        >
          Delete Account
        </h2>
        <div style={{ width: "100%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Password
            </div>
            <input
              placeholder="*************"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <div style={{ width: "100%" }}>
            <div
              style={{
                color: "#4D4D4D",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              Confirm Password
            </div>
            <input
              placeholder="*************"
              style={{
                border: "hidden",
                borderBottom: "2px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
              }}
            ></input>
          </div>
          <button style={{ marginTop: '20px', height: '40px', width: '30%', background: '#FFFFFF', border: '1px solid #D9D9D9 ', color: '#1C1154', fontSize: '17px', fontWeight: '450' }}>
                Delete Account
          </button>
      </div>
    </div>
  );
};

export default AccountInfo;
