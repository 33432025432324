import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginSuccess, loginFailure } from "../authSlice";
import "../styling/SignUp.css";
import { useNavigate } from "react-router-dom";


const Login = ({ handleIsclient }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const API_URL = process.env.REACT_APP_API_URL;
  // console.log(API_URL);
  // http://localhost:3001/business-user/login
  const handleLogin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/business-user/login`,
        {
          email,
          password,
        },
        { withCredentials: true }
      );
      dispatch(loginSuccess(response.data));
      console.log(response.data);
      const isClient = false;
      // Call handleIsclient to update parent state
      handleIsclient(isClient);

      // Navigate with isClient flag set to true
      navigate(`/?isClient=${isClient}`);
    } catch (error) {
      dispatch(loginFailure(error.message));
    }
  };

  return (
    <div
      className="login"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "70%", padding: "20px", textAlign: "center" }}>
        <div
          style={{
            fontSize: "35px",
            color: "#1C1154",
            marginBottom: "5px",
            fontWeight: "bold",
          }}
        >
          Login to Boothbloom
        </div>
        <input
          type="email"
          name="email"
          placeholder="Email"
          className="login-input"
          value={email}
          onChange={(e) => setUsername(e.target.value)}
          style={{
            width: "100%",
            marginBottom: "5px",
            padding: "10px",
            boxSizing: "border-box",
          }}
        />

        <input
          name="password"
          placeholder="Password"
          type="password"
          className="login-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{
            width: "100%",
            marginBottom: "5px",
            padding: "10px",
            boxSizing: "border-box",
          }}
        />
        <button
          className="login-button"
          style={{ width: "100%", marginTop: "15px", padding: "10px" }}
          onClick={handleLogin}
        >
          Login
        </button>
        <div style={{ marginTop: "15px" }}>
          <p>
            <span style={{ textDecoration: "underline" }}>
              Forgot Password?
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
