// Header.js
import React, { useState, useEffect } from "react";
import "../styling/Header.css";
import logo from "../images/BloomBoothLogo.png";
import profilePic from "../images/profilePic.jpg";
import { IoNotifications } from "react-icons/io5";
import CreateBookingModal from "./modals/CreateBookingModal";
import { useSelector } from "react-redux";
import data from "./data.json";
import { FaRegBuilding } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa6";
import { FaRegCalendar } from "react-icons/fa";
import { MdOutlineLogout } from "react-icons/md";
import axios from "axios";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";


const Header = ({ onClickAccountInfo }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [showCreateDropdown, setShowCreateDropdown] = useState(false);
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profileInfo, setProfileInfo] = useState(null);
  const [selectedItem, setSelectedItem] = useState("Clients");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);

  // Fetch notifications from the API
  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/business-user`, {
        withCredentials: true,
      }); // Replace with actual API endpoint

      console.log(response);
      const newNotifications = response.data.data.notification || [];

      // Calculate unread notifications
      const unreadNotifications = newNotifications.filter(
        (n) => !n.isRead
      ).length;

      setNotifications(newNotifications);
      setUnreadCount(unreadNotifications);
      console.log(newNotifications);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  // Toggle notifications panel and fetch notifications
  const toggleNotifications = () => {
    setShowNotifications((prev) => !prev);

    // Fetch notifications only when opening the panel
    if (!showNotifications) {
      fetchNotifications();
    }
  };

  const toggleProfileDropdown = () => {
    setShowProfileDropdown(!showProfileDropdown);
  };

  const handleSearchBarChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const toggleSearchCriteria = () => {
    setSelectedItem((prevItem) =>
      prevItem === "Clients" ? "Events" : "Clients"
    );
    setShowSearchDropdown(false);
  };

  const toggleSearchDropdown = () => {
    setShowSearchDropdown(!showSearchDropdown);
  };

  const toggleCreateDropdown = () => {
    setShowCreateDropdown(!showCreateDropdown);
  };

  const handleItemClick = (type) => {
    toggleProfileDropdown();
    onClickAccountInfo(type);
  };

  const filterResults = (query) => {
    if (query) {
      const results =
        selectedItem === "Clients"
          ? data.clients.filter((client) =>
              client.toLowerCase().includes(query.toLowerCase())
            )
          : data.events.filter((event) =>
              event.name.toLowerCase().includes(query.toLowerCase())
            );
      setFilteredResults(results);
    } else {
      setFilteredResults([]);
    }
  };

  const openNewBookingsModal = () => {
    toggleCreateDropdown();
    setIsModalOpen(true);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {" "}
      {isLoggedIn && (
        <header className="header" style={{ height: "50px" }}>
          <div style={{ display: "flex", width: "60%", marginTop: "-11px" }}>
            <img
              style={{ marginLeft: "1%", width: "15%" }}
              src="https://cdn.prod.website-files.com/65997e123627c8e86f726abe/65a5db5cbf3e4af51b463bd7_Boothbloom-logo-color.svg"
              alt=""
            />
            <button
              onClick={toggleSearchDropdown}
              style={{
                fontSize: "15px",
                marginTop: "13px",
                border: "1px solid #D9D9D9",
                height: "37px",
                marginLeft: "13px",
                paddingLeft: "12px",
                paddingRight: "12px",
                borderRight: "hidden",
                backgroundColor: "#FFFFFF",
                cursor: "pointer",
                borderRight: "none",
                borderRadius: "5px",
              }}
            >
              {selectedItem} <span>&#9662;</span>{" "}
            </button>
            {showSearchDropdown && (
              <div
                className="searchButton-dropdown"
                style={{ borderRadius: "5px" }}
              >
                <div>
                  {" "}
                  <button
                    style={{
                      fontSize: "15px",
                      paddingTop: "10px",
                      paddingLeft: "11px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "5px",
                      cursor: "pointer",
                      border: "none",
                    }}
                    onClick={toggleSearchCriteria}
                  >
                    {selectedItem === "Clients" ? "Events" : "Clients"}
                  </button>
                </div>
              </div>
            )}

            <div
              style={{
                position: "relative",
                width: "250px",
                marginTop: "13px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "13px",
                  color: "gray",
                }}
              >
                {/* <FaSearch /> */}
              </div>
              <input
                type="text"
                style={{
                  padding: "10px",
                  paddingLeft: "33px",
                  width: "100%",
                  border: "1px, solid, #d4d4d4",
                }}
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchBarChange}
              />

              {searchQuery && ( // Conditionally render search results
                <div
                  className="search-results"
                  style={{
                    position: "absolute",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #D9D9D9",
                    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                    marginTop: "5px",
                    width: "100%",
                    zIndex: "1",
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  {filteredResults.length > 0 ? ( // Display filtered results
                    filteredResults.map((result, index) => (
                      <div
                        key={index}
                        style={{
                          padding: "10px",
                          cursor: "pointer",
                          color: "#1C1154",
                        }}
                      >
                        <div>
                          {selectedItem === "Clients" ? result : result.name}
                        </div>
                        {selectedItem === "Events" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingTop: "6px",
                            }}
                          >
                            <div style={{ fontSize: "12px", color: "gray" }}>
                              {result.date} - {result.client}
                            </div>
                            {/* <div style={{ fontSize: "12px", color: "gray" }}>{result.client}</div> */}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div style={{ padding: "10px" }}>No results found</div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "17rem",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div style={{ marginBottom: "3px", width: "10rem" }}>
              <button
                className="createButtonStyle"
                onClick={openNewBookingsModal}
              >
                + Create New
              </button>
              {/* {showCreateDropdown && (
                <div className="createButton-dropdown">
                  <button
                    className="createNewBookingStyle"
                    onClick={openNewBookingsModal}
                  >
                    {" "}
                    New Booking  
                  </button>
                </div>
              )} */}
            </div>
            <div className="notification" onClick={toggleNotifications}>
              <IoNotifications
                style={{ fontSize: "24px", color: "rgb(29,16,83)" }}
              />
              {unreadCount > 0 && (
                <span className="notification-counter">{unreadCount}</span>
              )}
            </div>
            <div
              className="profile"
              onClick={toggleProfileDropdown}
              style={{ marginBottom: "6px" }}
            >
              <img src={profilePic} alt="" />
              {/* {showProfileDropdown && (
                <div className="profile-dropdown">
                  <ul>
                    <li>
                      <button
                        style={{ border: "hidden", backgroundColor: "#FFFFFF" }}
                        onClick={() => onClickAccountInfo("account")}
                      >
                        Account
                      </button>
                    </li>
                    <li>
                      <button
                        style={{ border: "hidden", backgroundColor: "#FFFFFF" }}
                        onClick={() => onClickAccountInfo("company")}
                      >
                        Company
                      </button>
                    </li>
                    <li>
                      <button
                        style={{ border: "hidden", backgroundColor: "#FFFFFF" }}
                        onClick={() => onClickAccountInfo("booth")}
                      >
                        Book-A-Booth
                      </button>
                    </li>
                  </ul>
                </div>
              )} */}
              {/* Notification Layover */}
            </div>
            <CreateBookingModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
            />
          </div>
          {showNotifications && (
            <div className="notification-layover">
              <div className="notification-title">
                <h2>Notifications</h2>
                <hr />
              </div>
              {notifications.length > 0 ? (
                notifications.map((notification, index) => (
                  <div
                    key={index}
                    className={`notification-item ${
                      notification.isRead ? "" : "unread"
                    }`}
                    onClick={() =>
                      console.log("Notification clicked:", notification)
                    }
                  >
                    <h4>{notification.title}</h4>
                    <p>{notification.message}</p>
                    <span className="timestamp">
                      {new Date(notification.timestamp).toLocaleString()}
                    </span>
                  </div>
                ))
              ) : (
                <p className="no-notifications">No new notifications</p>
              )}
              {/* <button onClick={markAsRead} className="mark-as-read-btn">
            Mark all as read
          </button> */}
            </div>
          )}
          {showProfileDropdown && (
            <div
              className="notification-layover"
              style={{
                width: "11%",
                right: "2%",
                padding: "0px",
                display: "block",
              }}
            >
              <button
                className="profile-dropdown-item"
                style={{
                  border: "1px solid  #F1F1F3",
                  backgroundColor: "#FFFFFF",
                  display: "flow",
                  width: "100%",
                  paddingLeft: "20%",
                  textAlign: "left",
                }}
                onClick={() => handleItemClick("account")}
              >
                <FaRegUser style={{}} /> Account
              </button>

              <button
                className="profile-dropdown-item"
                style={{
                  border: "1px solid  #F1F1F3",
                  backgroundColor: "#FFFFFF",
                  display: "flow",
                  width: "100%",
                  paddingLeft: "20%",
                  textAlign: "left",
                }}
                onClick={() => handleItemClick("company")}
              >
                <FaRegBuilding style={{}} />
                Company
              </button>

              <button
                className="profile-dropdown-item"
                style={{
                  border: "1px solid  #F1F1F3",
                  backgroundColor: "#FFFFFF",
                  display: "flow",
                  width: "100%",
                  paddingLeft: "20%",
                  textAlign: "left",
                }}
                onClick={() => handleItemClick("booth")}
                cursor="pointer"
              >
                <FaRegCalendar style={{}} /> Book-A-Booth
              </button>

              <button
                className="profile-dropdown-item"
                style={{
                  border: "1px solid  #F1F1F3",
                  backgroundColor: "#FFFFFF",
                  display: "flow",
                  width: "100%",
                  paddingLeft: "20%",
                  textAlign: "left",
                }}
                onClick={() => handleItemClick("booth")}
              >
                <MdOutlineLogout style={{}} /> Log Out
              </button>
            </div>
          )}
        </header>
      )}
    </div>
  );
};

export default Header;
